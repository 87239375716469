<template>
  <async-container :loading="loading">
    <v-card class="mb-5">
      <v-card-title>
        Livros
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="criarLivro">
          <v-icon class="mr-1">mdi-plus</v-icon>
          Criar
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Você tem acesso aos seguintes livros:</v-card-subtitle>
      <v-card-text class="d-flex" v-if="meusLivros.length">
        <v-sheet
            v-for="l in meusLivros"
            :key="l.id"
            class="ma-1"
            outlined
            rounded
        >
          <v-list-item dense class="px-2" @click="gerenciarAcessoLivro(l.id)">
            <v-list-item-icon>
              <v-icon class="mt-2">mdi-notebook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{l.nome}}</v-list-item-title>
              <v-list-item-subtitle v-if="l.manuais.length">{{l.manuais.length > 1 ? l.manuais.length + ' manuais' : l.manuais.length + ' manual'}}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>nenhum manual</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </v-card-text>
      <v-card-text class="text-center" v-else>
        <v-icon size="64">mdi-emoticon-cry-outline</v-icon>
        <p class="mb-0">Você não tem acesso a nenhum livro</p>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        Manuais
        <v-spacer></v-spacer>
        <v-btn small color="primary" to="/manual" :disabled="meusLivros.length === 0">
          <v-icon class="mr-1">mdi-plus</v-icon>
          Criar
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Manuais disponíveis nos seus livros</v-card-subtitle>
      <v-autocomplete
        label="Livro"
        prepend-inner-icon="mdi-notebook"
        :items="[{id: 0, nome: 'TODOS OS MEUS LIVROS'},...meusLivros]"
        item-text="nome"
        item-value="id"
        class="mx-3"
        outlined
        dense
        v-model="filtroLivro"
      ></v-autocomplete>
      <v-text-field
          class="px-3"
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          dense
          outlined
      ></v-text-field>
      <v-data-table
          :headers="headers"
          :items="meusManuais"
          :search="search"
          no-data-text="Nenhum manual disponível"
          no-results-text="Nenhum manual encontrado"
          sort-by="titulo"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.livros="{item}">
          <v-chip-group>
            <v-chip v-for="l in item.livros" :key="l.id" label small :color="l.acesso ? 'info' : 'light'">
              <v-icon size="16" class="mr-1">mdi-notebook</v-icon>{{l.nome}}
            </v-chip>
          </v-chip-group>
        </template>
        <template v-slot:item.atualizacao="{item}">{{parseDate(item.atualizacao)}}</template>
        <template v-slot:item.action="{item}">
          <div class="d-flex flex-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="primary"
                  v-on="on"
                  v-bind="attrs"
                  @click="visualizarManual(item.id)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="amber"
                  :to="'/manual/' + item.id"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Alterar</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="error"
                  v-on="on"
                  v-bind="attrs"
                  @click="removerManual"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogLoading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogManual" scrollable>
      <v-card v-if="manualVisualizacao">
        <v-card-title>
          {{manualVisualizacao.titulo}}
          <v-spacer></v-spacer>
          <v-icon @click="dialogManual = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mb-3"></v-divider>
        <v-card-text v-html="manualVisualizacao.texto"></v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLivro">
      <v-card v-if="iptLivro">
        <v-card-title>
          {{iptLivro.nome}}
          <v-spacer></v-spacer>
          <v-icon @click="dialogLivro = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>Usuários com acesso ao livro {{iptLivro.nome}}</v-card-subtitle>
        <v-autocomplete
          label="Adicionar usuario"
          :items="iptLivro.aptos"
          item-text="nome"
          item-value="cpf"
          v-model="iptCpfNovoIntegrante"
          class="mx-3"
          outlined
          dense
          hide-details
          :append-outer-icon="iptCpfNovoIntegrante ? 'mdi-send' : undefined"
          @click:append-outer="insertLivroAcesso(iptCpfNovoIntegrante, iptLivro.id)"
          :disabled="iptLivro.autor !== $store.getters['session/payload'].cpf && !permissaoAdm"
        ></v-autocomplete>
        <v-data-table
          :items="iptLivro.participantes"
          :headers="[
            {value: 'nome', text: 'Nome'},
            {value: 'action', text: 'Ações'},
          ]"
        >
          <template v-slot:item.action="{item}">
            <v-btn
              icon
              small
              color="error"
              @click="removeLivroAcesso(item.cpf, iptLivro.id)"
              title="Remover"
              :disabled="iptLivro.autor !== $store.getters['session/payload'].cpf && !permissaoAdm"
            >
              <v-icon>mdi-account-remove</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '@/components/interface/AsyncContainer';
  import ManualWebClient from '@/http/ManualWebClient';
  import moment from 'moment';
  import UserWebClient from "@/http/UserWebClient";

  export default {
    name: 'Manuais',
    components: {AsyncContainer},
    data: () => ({
      loading: true,
      webClient: new ManualWebClient(),
      livros: [],
      manuais: [],
      headers: [
        {value: 'titulo', text: 'Titulo', filterable: true},
        {value: 'livros', text: 'Livro', filterable: false, sortable: false},
        {value: 'atualizacao', text: 'Atualizado em', filterable: false, sortable: true},
        {value: 'action', text: 'Ações', filterable: false, sortable: false},
      ],
      search: '',
      filtroLivro: 0,
      manualVisualizacao: null,
      dialogManual: false,
      dialogLoading: false,
      dialogLivro: false,
      permissaoAdm: false,
      iptLivro: null,
      iptCpfNovoIntegrante: null,
    }),
    methods: {
      async loadData() {
        try {
          this.livros = await this.webClient.getLivros();
          this.manuais = await this.webClient.getManuais();
          const permissoes = await new UserWebClient().getPermissoes(this.$store.getters["session/payload"].user);
          this.permissaoAdm = !!permissoes.find(i => i.id === 7 && i.status === true);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          await this.$router.push('/');
        } finally {
          this.loading = false;
        }
      },
      async visualizarManual(id) {
        this.dialogLoading = true;
        try {
          this.manualVisualizacao = await this.webClient.getManual(id);
          this.dialogManual = true;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.dialogLoading = false;
        }
      },
      getLivrosQueEstaContido(manualId) {
        return this.livros.filter(i => i.manuais.includes(manualId));
      },
      parseDate(datetime) {
        return moment(datetime).format('DD/MM/YYYY HH:mm');
      },
      async criarLivro() {
        const nome = prompt('Informe o nome do livro');
        if (!nome || !nome.trim()) return;
        this.dialogLoading = true;
        try {
          await this.webClient.insertLivro(nome);
          await this.loadData();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.dialogLoading = false;
        }
      },
      async gerenciarAcessoLivro(id) {
        this.dialogLoading = true;
        try {
          const livro = await this.webClient.getLivro(id);
          const userWebClient = new UserWebClient();
          const usuarios = await userWebClient.getUsuarios();
          const participantesCpf = livro.participantes.map(u => u.cpf);
          livro.aptos = usuarios.filter(u => !participantesCpf.includes(u.cpf));
          this.iptCpfNovoIntegrante = null;
          this.iptLivro = livro;
          this.dialogLivro = true;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.dialogLoading = false;
        }
      },
      async insertLivroAcesso(cpf, livro) {
        this.dialogLoading = true;
        try {
          await this.webClient.insertLivroAcesso(cpf, livro);
          await this.gerenciarAcessoLivro(livro);
          this.iptCpfNovoIntegrante = null;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.dialogLoading = false;
        }
      },
      async removeLivroAcesso(cpf, livro) {
        this.dialogLoading = true;
        try {
          await this.webClient.removeLivroAcesso(cpf, livro);
          await this.gerenciarAcessoLivro(livro);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        } finally {
          this.dialogLoading = false;
        }
      },
      removerManual() {
        alert('Entre em contato com o setor TECIN. Por questões de segurança, somente o suporte pode remover um manual do sistema ou move-lo para outros livros.');
      },
    },
    created() {
      this.loadData();
    },
    computed: {
      meusLivros() {
        if (!this.livros) return [];
        return this.livros.filter(i => i.acesso);
      },
      meusManuais() {
        if (!this.manuais) return [];
        const manuais = this.manuais.filter(m => m.acesso);
        return manuais.map(m => {
          m.livros = this.getLivrosQueEstaContido(m.id);
          return m;
        });
      }
    }
  }
</script>
